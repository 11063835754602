import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/utils/axiosConfig.js'

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);


import '@/assets/styles/common.scss' // global css


import MyEchart from '@/components/echarts/myEchart.vue'
Vue.component('MyEchart', MyEchart)


import * as common from '@/utils/common.js'
Vue.prototype.$common = common

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
