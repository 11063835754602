
import {sizeAdjust} from '@/utils/common.js'
const fontSize =sizeAdjust(24);
const state = {



  fontSize:fontSize,
  lineStyleColor:"#7D92B5",

  color: ['#00FF00', '#10A2FF', '#00FFFF', '#FFC72F', '#947BEA', '#FF6464', '#FFFF00', '#638C0B', '#EA68A2', '#CFA972', '#8F82BC','#BFBFBF'],
  tooltip: {
    trigger: 'axis',
    backgroundColor:'rgba(3,41,55,0.8)',
    borderColor: '#61F4F3', // 边框颜色
    borderWidth: 2, // 边框宽度              
    borderRadius: 5,              
    
    textStyle: {
      color:'#fff',
      fontSize:fontSize,
    },
  },
  legend: {
    // icon: "circle", // circle，rect,line，roundRect，triangle，diamond，pin，arrow，none
    left:'center',
    textStyle: {
      color: "#fff",
      fontSize:fontSize
    },
    itemWidth: sizeAdjust(35),
    itemHeight: sizeAdjust(15),
    itemGap: sizeAdjust(25),
  },
  grid: { left: '2%', right: '2%', bottom: '2%', top:'16%', containLabel: true},
  xAxis: {
    // data: xData,
    type: 'category',
    boundaryGap: true,//坐标轴两边留白
    axisLabel: {
      margin: sizeAdjust(10),
      fontSize:fontSize,
      // interval: 0,
      // rotate: 40,
      color:'#fff',
      
    },
    axisLine:{
      lineStyle:{
        color:'rgba(255,255,255,0.2)'
      }
    },
    axisTick:{ 
      show:true,
      alignWithLabel: true, //刻度位置是否与标签对齐
      length: sizeAdjust(5),//刻度线的长度
    },
    splitLine:{show:false,lineStyle:{color:'#f5f5f5'}},             
  },
  yAxis: {
    // name: '(万吨标煤)',
    type: 'value',
    nameGap: sizeAdjust(20),
    minInterval: 1,//最小间隔值
    nameTextStyle:{
      fontSize:fontSize,
    },
    axisLine: {
      show: false,
      lineStyle: {
        color:"#7D92B5",
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255,255,255,0.2)'
      }
    },
    axisLabel: {
      fontSize:fontSize,
      color:'#fff',
    }
  },


}

const mutations = {


}

const actions = {


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
