<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import qs from 'qs'
import axios from 'axios';
const gettoken = (data) => axios.post('/service-oauthApi/oauth/token', data);
// 接口中service-dataService 改为 service-data-service

export default {
	created() {
	},
	mounted() {
    localStorage.setItem('token', 'token')
    let formData = {
      "grant_type":"password",
      "username":"huhanwen",
      "password":"1c242c50d1035c1f143d246bd3fa898b",
      "client_id":"portal",
      "client_secret":"123123",
    }
    let data = qs.stringify(formData)
  
    gettoken(data).then((res) => {
      localStorage.setItem('bigtoken', res.data.access_token)
    })
	},
	methods: {

	}
};
</script>

<style></style>
