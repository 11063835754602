import Vue from 'vue'
import Vuex from 'vuex'
import echart from './modules/echart'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activateArea:'资阳市',
    options:[
      {
        value: '资阳市',
        label: '全部区县',
      },
      {
        value: '雁江区',
        label: '都匀市',
      },
      {
        value: '安岳县',
        label: '平塘西县',
      },
      {
        value: '乐至县',
        label: '惠水县',
      },
      {
        value: '省内其他市',
        label: '独山县',
      },
      {
        value: '省外地区',
        label: '瓮安县',
      },
      {
        value: '福泉市',
        label: '福泉市',
      },
      {
        value: '荔波县',
        label: '荔波县',
      },
      {
        value: '平塘县',
        label: '平塘县',
      },
      {
        value: '罗甸县',
        label: '罗甸县',
      },
      {
        value: '长顺县',
        label: '长顺县',
      },
      {
        value: '贵定县',
        label: '贵定县',
      },
      {
        value: '三都水族自治县',
        label: '三都水族自治县',
      },



    ],


  },

  mutations: {
  },
  actions: {
  },
  modules: {
    echart,
  }
})
