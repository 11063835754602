import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

const routes = [

  {
    name: 'layout',
    path: '/',
    redirect: '/home',
    component: () => import('@/views/index.vue'),
    children: [
      {
        name: '需求地图',
        path: 'home',
        component: () => import('@/views/home/index.vue'),
      },

    ]

  },

];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(function(to, from, next) {
  document.title = `${to.name} | 数智康养城市 `; //标题

  next();
});

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router;
