/**
 * echarts大小自适应 sizeAdjust(14)
 * @param size 大小 单位px 
 * @returns 
 */
export function sizeAdjust(size) {
  let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (!clientWidth) return;
  let fontSize = clientWidth / 3840;
  return Number((size * fontSize).toFixed(2));
}

/**
  * 千分位显示处理
  * @param num  值
  * @param n  保留小数位数
  * @returns String
  */
export function formatNum(num, n = 2){
  if (num) {
    num = parseFloat(Number(num).toFixed(n))
    let [integer, decimal] = String.prototype.split.call(num, '.')
    integer = integer.replace(/\d(?=(\d{3})+$)/g, '$&,') // 正则先行断言
    return `${integer}${decimal ? '.' + decimal : ''}`
  } else {
    return 0
  }
}
/**
  * 处理数据中台来的数据
  * @param res  请求的返回值
  * @param discard  array  需要过滤的字段
  * @returns array
  */
export function bigDataDispose(res,discard = ['地区']){

  let _data = res.data.records[0] || {}
  let columns = res.data.columns
  let DictObj =[]
  for (const key in columns) {
    if (discard.indexOf(columns[key])) {

      let num = _data[key] || 0
      DictObj.push({
        key:key,
        name:columns[key],
        value:num
      })
    }

  }


  return DictObj
}