<template>
  <div :id="elId" :ref="elId" style="width:100%;height:100%" ></div>
</template>
<script>
import { v4 } from 'uuid'
import debounce from 'lodash/debounce'
import * as echarts from 'echarts';
import { autoToolTip as autoTip } from './echarts_auto_tooltip'
export default {
  name:'MyECharts',
  data() {
    return{
      myChart: null,
      elId: 'myEchart',
      resizeDebounce: null // 添加防抖函数的引用
    }
  },
  props: {
    option: {
      type: Object,
      default:()=>{
        return {}
      }
    },
    isOnClick: {
      type: Boolean,
      default:false
    },
    delay: {
      type: Number,
      default:0
    },
    autoTooltip: {//是否轮播提示
      type: Boolean,
      default:false
    },
    autoTooltipTime: {//轮播时间
      type: Number,
      default:3000
    }
  },
  computed:{

  },
  watch:{

    option:{
      handler(){
        this.loadEChart()
      },
      deep: true,
      // immediate:true, //初始化触发
    }
  },
  created() {
    this.elId = v4() // 避免key重复
  },  
  mounted() {
    let timer = setTimeout(()=>{
      this.loadEChart()
      clearTimeout(timer)
    },this.delay)



    let timer2 = setTimeout(()=>{
      // this.loadEChart()
      // 监听div容器变化
      const resizeTarget = this.$refs[this.elId];
      // 创建 ResizeObserver 实例 
      const resizeObserver = new ResizeObserver(() => {
        // entries 是一个 ResizeObserverEntry 对象数组，包含目标元素的大小信息
        // for (const entry of entries) {
        //   const { width, height } = entry.contentRect;
        //   console.log('Width:', width, 'Height:', height);
        // }
        this.myChart.resize();
      });
    
      // 开始监听目标元素的大小变化
      resizeObserver.observe(resizeTarget);
      // 在组件销毁时停止监听，防止内存泄漏
      this.$once('hook:beforeDestroy', () => {
        resizeObserver.disconnect();
      });

      clearTimeout(timer2)
    },1500)
    
  },  
  methods: {
    loadEChart() {
      if (this.myChart){
        this.myChart.dispose();
      }
      

      this.myChart = echarts.init(this.$refs[this.elId]);
      this.myChart.setOption(this.option);

      if (this.isOnClick) {
        this.myChart.on('click',  (params) =>{
          this.$emit('click',params)
        });
      }

      if (this.autoTooltip) {
        autoTip(this.myChart, this.option, {
          // 轮播间隔时间 默认3s
          interval: this.autoTooltipTime,
          // 是否循环轮播所有序列
          loopSeries: false,
          // 第1个被轮播的序列下标
          seriesIndex: 0,
        });
      }





      // //随着屏幕大小调节图表
      // window.addEventListener("resize", () => {
      //   this.myChart.resize();
      // });
      // 随着屏幕大小调节图表
      window.addEventListener("resize", this.resizeDebounce = debounce(() => {
        this.myChart.resize();
      }, 100)); // 使用防抖函数优化
    },

    /**
     * 高亮某一个数据
     * @name {String} 高亮的数据的name
     * @length {Number} 所有name Array的长度
     * @seriesIndex {Number} 数据的seriesIndex 默认0
     * @isShowTip {boolean} 是否显示tooltip默认true
    */
    highlightOne(name,length,seriesIndex=0,isShowTip=true){

      // 取消之前高亮的图形
      for (let index = 0; index <= length; index++) {
        this.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: seriesIndex,
          dataIndex: index,
        });
      }

      // 执行高亮
      this.myChart.dispatchAction({
        type: "highlight",
        seriesIndex: seriesIndex,
        name: name,
      });
      // 执行显示tip
      if (isShowTip) {
        this.myChart.dispatchAction({
          type: "showTip",
          seriesIndex: seriesIndex,
          name: name,
          // position:'top'
          position: function(point, params, dom, rect, size) {
            params, dom, rect
              // 提示框位置
              var x = 0; // x坐标位置
              var y = 0; // y坐标位置

              // 当前鼠标位置
              var pointX = point[0];
              var pointY = point[1];

              // 提示框大小
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1];

              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = 5;
              } else {
                // 左边放的下
                x = pointX - boxWidth;
              }

              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5;
              } else {
                // 上边放得下
                y = pointY - boxHeight;
              }

              return [x, y];
            }
        });
      }
      


    },
    /**
     * 取消之前高亮的图形
     * @length {Number} 所有name Array的长度
     * @seriesIndex {Number} 数据的seriesIndex 默认0
    */    
    downplay(length,seriesIndex=0){
      for (let index = 0; index <= length; index++) {
        this.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: seriesIndex,
          dataIndex: index,
        });
      }
    },

  },
  destroyed (){
    window.removeEventListener('resize', () => {
      this.myChart.resize();
    })
    if(this.myChart){
      this.myChart.dispose()
    } 
  }
}
</script>
